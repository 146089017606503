<template lang="pug">
    .auth-form(ref="authForm")
        transition(name="fadeIn")
            loading(v-if="isUserLoading")
        ._back.-other-phone(@click="step = 'phone'" v-if="step === 'confirm-phone'")
            svg-icon(icon-name="arrow-left")._back-icon
            ._back-text Ввести другой номер телефона
        ._back.-other-phone(@click="step = 'phone'" v-if="step === 'confirm-telegram'")
            svg-icon(icon-name="arrow-left")._back-icon
            ._back-text Вернуться назад
        ._input-group(v-if="step === 'phone'")
            label(for="number")._input-title Подтвердите свой номер
            ._input-group-desc Для того, чтобы пользоваться сайтом, Вам обязательно нужно подтвердить свой номер телефона:
            ._resend.-error(v-if="errorMessage || smsTimerCountdown > 0")
                template(v-if="smsTimerCountdown > 0") Вы недавно уже запрашивали звонок, поэтому новый вы сможете запросить через {{smsTimerCountdown}} сек.
                template(v-else) {{errorMessage}}
            ._inline-inputs
                ._input-helper
                    ._countru-code
                        input(v-model.number="countryCode" @keydown="codeDropdownStatus = true" @blur="changeCountryCode('temporary')" v-mask="['+#', '+##', '+###']")._input.-country-code
                        transition(name="show-code-dropdown" appear)
                            ._country-code-dropdown(v-show="codeDropdownStatus")
                                ._top-left-decor
                                ._top-right-decor
                                ._bottom-left-decor
                                ._bottom-right-decor
                                perfect-scrollbar._country-code-dropdown-inner
                                    ._country-code-item(v-for="code in countryCodesFiltered" @click.stop="changeCountryCode(code.phone_code)") {{ code.full }}
                                    ._country-code-empty(v-if="!countryCodesFiltered.length") Ничего не найдено
                    ._input-help Код страны
                ._input-helper.-phone
                    input(type="tel" inputmode="numeric" v-model="number" id="number" v-focus @keyup.enter="getCode()" v-mask="countryCode === '+7' ? ['(###) ### ## ##'] : ['### ### ### ###']")._input
                    ._input-help Номер телефона
            ui-button(@click.native="getCode()" size="full" :arrow="true" :text="confirmBtnText" :disabled="!allowedSmsSend")._btn
            promocode._promocode(@changePromocode="changePromocode")
            ._agree-text {{ locale("agree_1") }}
                router-link(to="/docs/term-of-use")._agree-link {{ locale("agree_2") }}
                |  {{ locale("agree_and") }}&nbsp;
                router-link(to="/docs/privacy-policy.html" target="_blank")._agree-link {{ locale("agree_3") }}
                | .

        ._input-group(v-if="step === 'confirm-phone'")
            ._phone
                ._phone-type {{ confirmationType === "CALL" ? "Входящий звонок" : "Входящее сообщение" }}
                ._phone-indicator
                    svg-icon(:icon-name="confirmationType.toLowerCase()")._phone-icon
                div(:class="exampleClass" v-html="exampleText")
            ._code-group
                ._code-text(v-html="codeText")
                input(type="number" inputmode="numeric" v-mask="['####']" placeholder="X X X X" v-model="smsCode"  ref='codeInput' v-focus @keyup.enter="confirmSmsCode" @keyup="confirmSmsCode")._input.-small

            ._resend.active(v-if="errorMessage")
                template(v-if="smsTimerCountdown > 0 && errorMessage === 'BLOCKED'") Ваш номер был недавно заблокирован, Вы сможете начать сначала через {{smsTimerCountdown}} сек.
                template(v-else) {{errorMessage}}

            ._resend.active(v-else)
                template(v-if="smsTimerCountdown > 0") Если по какой-либо причине до Вас не дозвонились или не дошло СМС сообщение, то Вы сможете запросить код повторно через {{smsTimerCountdown}} сек.
                ui-button(v-else text="запросить код повторно" @click.native="getCode()" size="full")._btn

            ui-button(:text="enterBtnText" size="full" @click.native="confirmSmsCode()" :disabled="loading || $v.smsCode.$error" :class="{disabled: loading || $v.smsCode.$error}" :arrow="true")._btn

</template>

<script>
import {
    between,
    maxLength,
    minLength,
    required,
} from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import { phoneConfirmMixins } from "../../../mixins/phoneConfirmMixins";
import UiButton from "../../ui/ui-button/ui-button";
import Promocode from "../../promocode/promocode";
import { vueTelegramLogin } from "vue-telegram-login";
import gql from "graphql-tag";
import exchangeTelegramPayloadToTokenMutation from "@/graphql/mutations/exchangeTelegramPayloadToToken.mutation.gql";

export default {
    name: "PhoneForm",
    components: { Promocode, UiButton, vueTelegramLogin },
    directives: {
        mask,
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
    mixins: [phoneConfirmMixins],
    data: () => ({
        step: "phone",
        number: "",
        smsCode: "",
        smsTimer: null,
        smsTimerCountdown: 0,
        smsRetryDelay: 30,
        loading: false,
        blocked: false,
        errorMessage: "",
        resendInterval: "",
        resendTime: 5,
        requestsSent: 0,
        confirmationType: "CALL",
        countryCodeMutable: "",
        codeDropdownStatus: false,
        countryCode: "+7",
        temporaryCode: "+7",
        codeTimeout: null,
        promocode: "",
        allowTryGetMyUser: true,
        isUserLoading: false,
        isFetchAccountSend: false,
        telegramTimeout: null,
    }),
    validations: {
        smsCode: {
            required,
            between: between(1000, 9999),
            minLength: minLength(4),
            maxLength: maxLength(4),
        },
    },
    computed: {
        telegramAuthText: (state) =>
            state.isFetchAccountSend
                ? "Запрос от бота получен. Выполняется авторизация"
                : "В течение минуты Мерлин отправит вам сообщение в телеграмме",
        countryCodesFiltered: (state) =>
            state.countryCodes.filter((item) =>
                item.phone_code.includes(state.countryCode),
            ),
        codeText: (state) =>
            state.confirmationType === "CALL"
                ? "Введите ниже последние 4&nbsp;цифры входящего номера"
                : "Введите код из СМС",
        exampleClass: (state) =>
            state.confirmationType === "CALL"
                ? "auth-form__phone-example"
                : "auth-form__message-example",
        exampleText: (state) =>
            state.confirmationType === "CALL"
                ? "+7 (XXX) XXX <span>XXXX</span>"
                : "Код подтверждения: <span>XXХХ</span>",
        allowedSmsSend: function () {
            if (this.isValidPhone === false) {
                return false;
            }
            return this.smsTimerCountdown <= 0 && !this.loading;
        },
        phoneNumber() {
            return this.countryCode + this.number;
        },
        isValidPhone() {
            let phone = this.phoneNumber;
            if (this.number === "" || this.number.length < 5) {
                return false;
            }
            if (phone[0] !== "+") {
                phone = `+${phone}`;
            }
            const phoneNumber = phone.replace(/[^0-9+]/g, "");
            return /^\+(?:[0-9]?){6,14}[0-9]$/.test(phoneNumber);
        },
        isRussianPhone: function () {
            return /^\+7\d{10}$/.test(this.phoneNumber);
        },
        isCisNumber: function () {
            return /^\+(?:99[2468]\d{9}|37[34]\d{8}|375\d{9}|993\d{8}|380\d{9})$/.test(
                this.phoneNumber,
            );
        },
        confirmBtnText: (state) =>
            !state.loading ? "Подтвердить" : "Запрос...",
        enterBtnText: (state) => (!state.loading ? "Войти" : "Загрузка..."),
    },
    watch: {
        smsCode: function () {
            this.$v.smsCode.$touch();
        },
        resendTime(newValue) {
            if (!newValue) {
                clearInterval(this.resendInterval);
                this.resendInterval = "";
            }
        },
        smsTimerCountdown: function () {
            if (this.smsTimerCountdown === 0) return;
            setTimeout(() => {
                if (this.smsTimerCountdown <= 0) {
                    this.smsTimerCountdown = 0;
                    return;
                }
                this.smsTimerCountdown--;
            }, 1000);
        },
        step() {
            clearTimeout(this.telegramTimeout);
        },
    },
    mounted() {
        this.$store.dispatch("set", {
            name: "onboardingCtrlStepNumber",
            value: 6,
        });
        this.$v.$touch();
        if (localStorage.phone_code) {
            this.changeCountryCode(localStorage.phone_code);
        }
    },
    methods: {
        getFrameDocument(frame) {
            return (
                frame && (frame.contentDocument || frame.contentWindow || null)
            );
        },
        changePromocode(code) {
            this.promocode = code;
        },
        changeCountryCode(code) {
            if (code === "temporary") {
                this.codeTimeout = setTimeout(() => {
                    if (
                        this.countryCodes.find(
                            (i) =>
                                i.phone_code === `+${this.countryCode}` ||
                                i.phone_code === `${this.countryCode}`,
                        )
                    ) {
                        if (this.countryCode[0] !== "+") {
                            this.countryCode = `+${this.countryCode}`;
                        }
                        this.codeDropdownStatus = false;
                        localStorage.phone_code = this.countryCode;
                        this.temporaryCode = `+${this.countryCode}`;
                        return;
                    }
                    this.codeDropdownStatus = false;
                    this.countryCode = `+${this.temporaryCode}`;
                }, 200);
                return;
            }
            clearTimeout(this.codeTimeout);
            this.codeTimeout = null;
            this.codeDropdownStatus = false;
            localStorage.phone_code = code;
            this.countryCode = code;
            this.temporaryCode = code;
        },
        getCode() {
            this.loading = true;
            this.blocked = false;
            this.errorMessage = "";
            let phone = this.phoneNumber.replace(/[^0-9+]/g, "");
            if (phone[0] !== "+") {
                phone = `+${phone}`;
            }
            this.newUserPhone(phone)
                .then((response) => {
                    this.loading = false;
                    switch (response.data.newUserPhone.status) {
                        case "OK":
                            this.step = "confirm-phone";
                            this.smsTimerCountdown = this.smsRetryDelay;
                            break;
                        case "WAIT":
                            this.smsTimerCountdown =
                                response.data.newUserPhone.wait;
                            break;
                        case "BLOCKED":
                        case "ALREADY_HAS_CONFIRMED":
                            this.smsTimerCountdown =
                                response.data.newUserPhone.wait;
                            break;
                        case "SEND_ERROR":
                            this.errorMessage =
                                "Ошибка отправки запроса звонка, попробуйте снова через несколько секунд.";
                            break;
                        case "ERROR":
                            this.errorMessage =
                                "Пожалуйста, проверьте правильность ввода Вашего номера телефона";
                            break;
                    }

                    this.confirmationType =
                        response.data.newUserPhone.confirmationType;
                })
                .catch((e) => {
                    this.loading = false;

                    if (e.graphQLErrors) {
                        let errorCode = e.graphQLErrors[0].extensions.code;
                        switch (errorCode) {
                            case 1006:
                                this.errorMessage =
                                    "Укажите правильный номер телефона в международном формате. Пример для России: +79174246633";
                                break;
                            default:
                                this.errorMessage =
                                    "Неизвестная ошибка, попробуйте снова через несколько минут.";
                        }
                    }
                });
        },
        async confirmSmsCode(e) {
            if (e.target.value.length < 4) return;

            const referralCookieKey = "referralCode";
            let referralCode = "";
            if (this.$cookies.isKey(referralCookieKey) === true) {
                referralCode = this.$cookies.get(referralCookieKey);
                this.$cookies.remove(referralCookieKey);
            }
            this.loading = true;
            this.blocked = false;
            this.errorMessage = "";
            this.loading = true;
            let phone = this.phoneNumber.replace(/[^0-9+]/g, "");
            if (phone[0] !== "+") {
                phone = `+${phone}`;
            }
            this.newUserPhoneVerify(
                phone,
                this.smsCode,
                referralCode,
                this.promocode,
            )
                .then(async (response) => {
                    this.loading = false;
                    const responseData = response.data.newUserPhoneVerify;
                    switch (responseData.status) {
                        case "OK":
                            if(this.$metrika?.reachGoal) {
                                const v = localStorage.getItem("landing") || "";
                                this.$metrika.reachGoal(`Level_2_phone${v}`);
                            }

                            await this.$store.dispatch("auth/successAuth", {
                                jwt: responseData.jwt,
                                refresh_token: responseData.refresh_token,
                                user_id: responseData.user_uuid,
                            });
                            this.yandexGoal("phone");
                            await this.getMyUser();
                            await this.getOnBoardingTypesFromMixin();
                            break;
                        case "NOT_FOUND":
                            this.requestsSent = 0;
                            this.errorMessage =
                                "Вы не успели ввести последние четыре цифры в течении 60 сек. Пожалуйста, начните процедуру подтверждения заново.";
                            this.step = "phone";
                            break;
                        case "ALREADY_HAS_CONFIRMED":
                            this.errorMessage =
                                "Вы уже подтвердили номер телефона.";
                            break;
                        case "WRONG_CODE":
                            this.errorMessage =
                                "Неправильные цифры, осталось попыток: " +
                                responseData.attempts +
                                " из 3";
                            break;
                        case "BLOCKED":
                            this.requestsSent = 0;
                            this.errorMessage = "BLOCKED";
                            this.smsTimerCountdown = 0;
                            setTimeout(() => {
                                this.smsTimerCountdown = responseData.wait;
                            }, 100);
                            break;
                        case "SEND_ERROR":
                            this.errorMessage =
                                "Ошибка запроса звонка, попробуйте снова через несколько секунд.";
                            break;
                    }
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.graphQLErrors) {
                        let errorCode = e.graphQLErrors[0].extensions.code;
                        switch (errorCode) {
                            case 1006:
                                if (this.confirmationType === "CALL") {
                                    this.errorMessage =
                                        "Укажите последние 4 цифры телефона, который Вам звонит.";
                                } else {
                                    this.errorMessage =
                                        "Введите проверочный код, который отправлен Вам по смс.";
                                }
                                break;
                            default:
                                this.errorMessage =
                                    "Неизвестная ошибка, попробуйте снова через несколько минут.";
                        }
                    }
                });
        },
        async getMyUser() {
            this.isUserLoading = true;

            await this.loadMyUser(true);
        },
        telegramHandler(user) {
            let userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            if (user?.hash) {
                this.$apollo
                    .mutate({
                        mutation: gql(exchangeTelegramPayloadToTokenMutation),
                        variables: {
                            payload: user,
                            userAgent,
                        },
                    })
                    .then(async (r) => {
                        if (r.data.exchangeTelegramPayloadToToken?.jwt) {
                            const responseData =
                                r.data.exchangeTelegramPayloadToToken;
                            await this.$store.dispatch("auth/successAuth", {
                                jwt: responseData.jwt,
                                refresh_token: responseData.refresh_token,
                                user_id: responseData.user_uuid,
                            });
                            await this.getMyUser();
                            await this.getOnBoardingTypesFromMixin();
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors[0].extensions.code === 1002) {
                            if (this.step === "confirm-telegram") {
                                this.fetchAccount(user);
                                this.telegramTimeout = setTimeout(() => {
                                    this.telegramHandler(user);
                                }, 3000);
                            }
                        }
                    });
                this.step = "confirm-telegram";
            }
        },
        async fetchAccount(user) {
            if (this.isFetchAccountSend) return;
            let str = "";

            Object.keys(user).forEach((param) => {
                str += `${param}=${user[param]}&`;
            });

            await fetch(`https://merlinface.com/signin/telegram?${str}`, {
                headers: {
                    Referer: "https://merlinface.com",
                },
            });

            this.isFetchAccountSend = true;
        },
    },
};
</script>

<style lang="scss">
@import "auth-form";
</style>
